@use "@material/fab";

@include fab.core-styles;

.top-button {
  @include fab.ink-color(#298B57);
  @include fab.container-color(white);
}

/* Safari / iOS / Hack to Fix Scrolling Inputs on Mobile */
html {
  -webkit-overflow-scrolling: touch !important;
}

/* START: Update App from 1140 width to 1240 width */
.app-container {
  max-width: 1380px;
  margin: 0 auto;
}

@media (min-width: 1200px) {
  app-new-order-page .container {
    max-width: 1380px;
  }
}

/* change icon to clock for time dropdown */
app-new-order-page pdl-dynamic-form > div > div:nth-child(1) > div:nth-child(3) > pdl-question .ng-arrow:after{
  content: 'schedule';
  font-family: "Material Icons";
  background: none;
  /* NOTE: do we need disabled state? color: #00483A ; */
  color: #00483b;
  position: relative;
  top: -22px;
  font-size: 24px;
  right: -15px;
}

app-new-order-page .modal-body .form-group {
  padding-top: 8px;
  padding-bottom: 8px;
}

app-new-order-page .suppress-error-box .error-summary {
  display: none;
}

app-new-order-page input.form-control {
  font-weight: 600;
}

app-new-order-page .abc-radio-group{
  position: relative;
  top: 0px;
}

app-new-order-page tbody button {
  margin: 0 !important;
  padding: 0 !important;
}

// app-new-order-page .input-group-append {
//   margin-top: 10px;
// }

app-new-order-page .modal-body .form-group {
  border-top: none;
}

.nav-bar {
  max-width: 1380px;
}

.header-bar--collapse .header-bar__container {
  width: 1380px;
  margin: 0 auto;
  max-width: 1380px;
}
/* END: Update App from 1140 width to 1240 width */

.ngx-datatable .datatable-header {
  background-color: #dcdcdc;
}

@-moz-document url-prefix() {
app-datatable .ngx-datatable.scroll-vertical.virtualized .datatable-body .datatable-row-wrapper {
  position: unset;
}
}

::-webkit-scrollbar-track
{
  background-color: #fafafa;
}

::-webkit-scrollbar {
  width: 18px  !important;
  height: 18px !important;
  background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb
{
  border-radius: 10px;
  background-color: #c3c3c3;
  border: 4px solid #fafafa;
  &:hover {
    background-color: #acacac;
    cursor: pointer;
  }
}

::-webkit-scrollbar-thumb:vertical {
  min-height: 40px;
}

/* Adjusts Line Height on Table Cells And Clamps Lines */
.datatable-body-cell small {
  display: block;
  line-height: 16px;
  font-size: 12px !important;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  /* autoprefixer: ignore next */
  -webkit-box-orient: vertical;
  overflow: hidden;
  max-height: 48px;
}

.ngx-table-cell {
  display: block;
  line-height: 16px;
  font-size: 12px !important;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  /* autoprefixer: ignore next */
  -webkit-box-orient: vertical;
  overflow: hidden;
  max-height: 48px;
}

/* Hack to Ensure All Table Text is 12px */
datatable-body-cell {
  font-size: 12px !important;
}

/* Reposition the Edit Filters Button */
/* This is different from the static design system because the button is placed
in a different location, but probably not for all apps. */
.button--filters {
  margin-top: 4px !important;
}

/* Title for Filter Bar Reports */
.report-title {
  display: block;
  color: #4a4a4a;
  position: relative;
  left: 4px;
  width: 50%;
}

/* Ticket Modal Image and PDF */
.ticket-image {
  width: 100%;
  padding: 20px 14px 10px 14px;
}
.ticket-pdf {
  width: 100%;
  height: 720px;
  padding: 20px 14px 10px 14px;
}
.ticket-image-container {
  min-height: 300px;
  background-image: url('data:image/svg+xml;utf-8,<svg width="169px" height="76px" viewBox="0 0 169 76" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g id="Group" transform="translate(-1.000000, -4.000000)"><g id="baseline-not_interested-24px" transform="translate(86.000000, 27.000000) scale(-1, 1) translate(-86.000000, -27.000000) translate(59.000000, 0.000000)"><polygon id="Path" points="0 0 54 0 54 54 0 54"></polygon><path d="M27,4 C14.304,4 4,14.304 4,27 C4,39.696 14.304,50 27,50 C39.696,50 50,39.696 50,27 C50,14.304 39.696,4 27,4 Z M27,45.4 C16.834,45.4 8.6,37.166 8.6,27 C8.6,22.745 10.049,18.835 12.487,15.73 L38.27,41.513 C35.165,43.951 31.255,45.4 27,45.4 Z M41.513,38.27 L15.73,12.487 C18.835,10.049 22.745,8.6 27,8.6 C37.166,8.6 45.4,16.834 45.4,27 C45.4,31.255 43.951,35.165 41.513,38.27 Z" id="Shape" fill="#E4E4E4" fill-rule="nonzero"></path></g><text id="No-Image-Data-Availa" font-family="OpenSans, Open Sans" font-size="15" font-weight="normal" fill="%234A4A4A"><tspan x="0.235839844" y="76">No Image Data Available</tspan></text></g></g></svg>');
  background-repeat: no-repeat;
  background-position: center;
}
.ticket-pdf-container {
  min-height: 300px;
  background-image: url('data:image/svg+xml;utf-8,<svg width="154px" height="73px" viewBox="0 0 154 73" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g id="Group" transform="translate(-1.000000, -4.000000)"><g id="baseline-not_interested-24px" transform="translate(78.000000, 27.000000) scale(-1, 1) translate(-78.000000, -27.000000) translate(51.000000, 0.000000)"><polygon id="Path" points="0 0 54 0 54 54 0 54"></polygon><path d="M27,4 C14.304,4 4,14.304 4,27 C4,39.696 14.304,50 27,50 C39.696,50 50,39.696 50,27 C50,14.304 39.696,4 27,4 Z M27,45.4 C16.834,45.4 8.6,37.166 8.6,27 C8.6,22.745 10.049,18.835 12.487,15.73 L38.27,41.513 C35.165,43.951 31.255,45.4 27,45.4 Z M41.513,38.27 L15.73,12.487 C18.835,10.049 22.745,8.6 27,8.6 C37.166,8.6 45.4,16.834 45.4,27 C45.4,31.255 43.951,35.165 41.513,38.27 Z" id="Shape" fill="#E4E4E4" fill-rule="nonzero"></path></g><text id="No-PDF-Data-Availabl" font-family="OpenSans, Open Sans" font-size="15" font-weight="normal" fill="%234A4A4A"><tspan x="0.638671875" y="76">No PDF Data Available</tspan></text></g></g></svg>');
  background-repeat: no-repeat;
  background-position: center;
}

/* Filters Bar */
.filter__item {
  font-size: 15px;
  line-height: 19px;
  text-transform: none;
}
.filter__showall button {
  padding-left: 0px;
  padding-bottom: 0px;
  padding-right: 0px;
  margin-left: 0px;
  margin-bottom: 0px;
  margin-right: 0px;
  font-weight: 700;
}
.header-bar__filters {
  overflow: hidden;
}
.header-bar__filters::-webkit-scrollbar {
  display: none;
  background: transparent;
}
.header-bar__absolute {
  position: absolute;
  width: 100vw;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.08);
}
.header-bar__reposition {
  margin-top: 60px;
}

/* Empty Table */
.empty-row {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background: white;
}

/* Ngx-DataTable Adjustments */
.cell-label {
  font-size: 11px;
}

input::-webkit-date-and-time-value {
  text-align: left;
  margin: 0px;
  padding: 0px;
  line-height: 28px;
}

@media only screen and (max-width: 576px) {
  .list-accordion__item__content .col-md-6, .list-accordion__item__content .col-md-12 {
    margin-bottom: 10px;
  }
  .list-accordion__item {
    padding-bottom: 0px;
  }
  .list-accordion__item__content .col-lg-4 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

/* Refactor Later -- ngx-datatable footer styling */
/* Need to add this to the Angular Design System */

.ngx-datatable .datatable-footer .datatable-pager .pager li a {
  cursor: pointer;
  display: inline-block;
  width: 30px;
  height: 30px;
  line-height: 29px;
  margin: 2px;
  text-align: center;
  vertical-align: middle;
  display: inline-block;
  border: 1px solid #9B9B9B;
  border-radius: 50%;
  color: #9B9B9B;
  &:hover {
    color: white;
    background: #9B9B9B;
    text-decoration: none;
  }
}

.ngx-datatable .datatable-footer .datatable-pager .pager li.pages.active a {
  cursor: pointer;
  display: inline-block;
  width: 30px;
  height: 30px;
  line-height: 29px;
  margin: 2px;
  text-align: center;
  vertical-align: middle;
  display: inline-block;
  border: 1px solid #9B9B9B;
  border-radius: 50%;
  color: white;
  background: #298B57;
  border: 1px solid #298B57;
  &:hover {
    color: white;
    background: #298B57;
    text-decoration: none;
  }
}

i.datatable-icon-right:after {
  font-family: "Material Icons";
  content: "\e315";
  font-size: 14px;
  font-style: normal;
  position: relative;
  left: 1px;
}

i.datatable-icon-left:after {
  font-family: "Material Icons";
  content: "\e314";
  font-size: 14px;
  font-style: normal;
  position: relative;
  left: 1px;
}

i.datatable-icon-prev:after {
  font-family: "Material Icons";
  content: "\e5dc";
  font-size: 14px;
  font-style: normal;
  position: relative;
  left: 1px;
}

i.datatable-icon-skip:after {
  font-family: "Material Icons";
  content: "\e5dd";
  font-size: 14px;
  font-style: normal;
  position: relative;
  left: 1px;
}

@media print {
  @page {
    size: auto !important;
  }
  .datatable-header-cell {
    background-color: #DCDCDC;
    -webkit-print-color-adjust: exact;
  }

  .datatable-row-group {
    border-bottom: 1px solid #DCDCDC;
  }

  .nav-bar {
    display: none;
  }

  .header-bar--full-width {
    display: none;
  }

  .header-bar {
    display: none;
  }

}

.drop-down .drop-down__menu{
  z-index: 1040;
}
#exampleModalLarge > div > div > div.modal-body > div > pdl-dynamic-form > div:nth-child(3) > div:nth-child(1) > pdl-question > div > div {
  white-space: pre-wrap;
}
#exampleModalLarge > div > div > div.modal-body > div > pdl-dynamic-form > div:nth-child(3) > div:nth-child(2) > pdl-question > div > div {
  white-space: pre-wrap;
}

/* Saved Table Hacks */
/* Hacks for Getting Rid of Sort Icon and Adding a Check Mark */

.table__submit__clear .datatable-header-cell:last-of-type {
  .datatable-header-cell-template-wrap {
    .sort-btn {
      display: none;
    }
  }
}

.table__submit__check .datatable-header-cell:first-of-type {
  .datatable-header-cell-template-wrap {
    .sort-btn:before {
      background-image: none;
      content: "\e5ca";
      font-family: "Material Icons";
      left: -5px;
      position: relative;
      font-size: 15px;
    }
  }
}

.datatable-body-cell-label{
  white-space: normal;
}

.not-interested{
  opacity: .15;
  transform: rotate(90deg);
  font-size: 54px;
}

.saved-name {
  display: block;
  line-height: 16px;
  font-size: 12px !important;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  /* autoprefixer: ignore next */
  -webkit-box-orient: vertical;
  overflow: hidden;
  max-height: 16px;
}

/* Report Adjustments */

.tonTotals {
  display: block;
  font-weight: 400;
  span {
    font-weight: 700;
  }
}

.loadTotals {
  display: block;
  font-weight: 400;
  span {
    font-weight: 700;
  }
}

.reportTotals {

}

/* Loading Adjustments for Scoped Routes */

app-tickets .loading-data {
  margin-top: 66px;
}

app-orders .loading-data {
  margin-top: 66px;
}

app-schedules .loading-data {
  margin-top: 66px;
}

app-reports .loading-data {
  margin-top: 0px;
}

app-saved .loading-data {
  margin-top: 66px;
}

// tickets datatable height
body > app-root > div > app-tickets > app-datatable > div.row > div > ngx-datatable {
  height: calc(100vh - 200px);
}

// orders datatable height
body > app-root > div > app-orders > app-datatable > div.row > div > ngx-datatable {
  height: calc(100vh - 200px);
}

// saved datatable height
body > app-root > div > app-saved app-datatable > div.row > div > ngx-datatable{
  height: calc(100vh - 200px);
}

// admin datatable height
body > app-root > div > app-admin > app-datatable > div.row > div > ngx-datatable{
  height: calc(100vh - 160px);
}

// reports not by day datatable height
body > app-root > div > app-reports > app-product-code-job-report > app-datatable > div.row > div > ngx-datatable {
  height: calc(100vh - 220px);
}

// reports by day datatable height
body > app-root > div > app-reports ngx-datatable {
  height: calc(50vh);
}

// Adjusted margin top for tickets page
app-tickets > app-datatable  div.header-bar, app-saved > app-datatable div.header-bar {
  margin-top: 60px;
}

// Adjusted margin top for orders page
app-orders > app-datatable  div.header-bar, app-saved > app-datatable div.header-bar {
  margin-top: 60px;
}

app-admin {
  display: block;
  margin-top: 32px;
}

app-saved app-datatable-header div.header-bar {
  margin-top: 80px;
}

// Adjusted margin top for reports page
app-reports app-datatable-header div.header-bar {
  margin-top: 80px;
}

// Adjusted margin top for graphs page
app-graphs app-datatable-header div.header-bar {
  margin-top: 80px;
}

// Adjusted margin top for reports page abc checkbox
.abc-radio-group {
  margin-top: 4px;
}

// Adjusted margin top for reports page abc checkbox
app-create-plant-schedule-report-modal .abc-radio-group {
  margin-top: 16px;
}

.header-bar pdl-drop-down button {

}

input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
  display: inline-block;
  width: 9px;
  height: 9px;
  background: linear-gradient(to bottom right, transparent 43%, #999 45%, #999 55%, transparent 57%), linear-gradient(to bottom left , transparent 43%, #999 45%, #999 55%, transparent 57%), #fff;
  right: 2px;
  position: relative;
  &:hover {
    cursor: pointer;
    background: linear-gradient(to bottom right, transparent 43%, #D0021B 45%, #D0021B 55%, transparent 57%), linear-gradient(to bottom left , transparent 43%, #D0021B 45%, #D0021B 55%, transparent 57%), #fff;
  }
}

p.text-center {
  margin: 0px;
}

@media only screen and (min-width: 1000px) {

  ::-webkit-scrollbar-thumb:horizontal {
    height: 0px;
  }

  ::-webkit-scrollbar {
    height: 0px;
  }

}

// Navbar Brand Icon Text Adjustments
.nav-bar .nav-bar__logo .nav-bar__logo__link .icon span {
  color: #000000;
  font-weight: 700;
  font-size: 18px;
  position: relative;
  top: -7px;
  left: 14px;
}
@media only screen and (max-width: 672px) {
  // .nav-bar .nav-bar__logo .nav-bar__logo__link .icon span {
  //   display: none;
  // }
}

// IE Modal Display
#ieModal {
  display: block;
}

@supports (display: grid) {
  #ieModal {
    display: none;
  }
}

// Graphing Edits

.chart-legend .legend-title {
  font-size: 12px !important;
  margin-bottom: 8px !important;
  margin-left: 0px !important;
  text-transform: uppercase !important;
  display: none !important;
}

.chart-legend .legend-labels {
  // background: #f6f6f690 !important;
  // max-height: 100% !important;
  // border: 1px solid #DCDCDC !important;
  background: white !important;
  border: 0px solid #DCDCDC !important;
  max-height: 100% !important;
}

.chart-legend .legend-label {
  color: #4a4a4a !important;
  font-size: 100% !important;
}

.chart-legend .legend-label {
  margin-left: 0px !important;
}

.chart-legend .legend-label-text {
  color: #4a4a4a !important;
}

.legend-label-text {
  &:hover {
    font-weight: 700;
  }
}

.chart-legend {
  margin-top: -3px !important;
}

.ngx-charts .tooltip-anchor {
  fill: #4a4a4a;
}

g.axis > g:last-child > text {
  text-transform: uppercase;
  font-size: 12px;
  fill: #4a4a4a;
  font-weight: 700;
}

// g.x.axis {
//   .tick {
//     text { transform: rotate(-45deg) !important; }
//   }
// }

.timeline .brush .selection {
  fill: rgb(41 139 87 / 15%) !important;
  stroke: #298b57 !important;
  stroke-width: 2px !important;
}

rect.overlay {
  stroke: #dcdcdc !important;
  stroke-width: 2px !important;
}

g.ngx-charts-timeline {
  margin-top: 500px;
}

#info-tip {
  position: absolute;
  bottom: 0px;
  position: absolute;
  bottom: 70px;
  z-index: 1000;
  right: 234px;
  .material-icons {
    color: #298B57;
  }
}

.info-tip--body {
  font-weight: 500;
}

/* The element to hover over */
.qs {
  background-color: #298b57;
  border-radius: 9999px;
  color: #e3fbff;
  cursor: default;
  display: inline-block;
  font-family: "Helvetica", sans-serif;
  font-size: 17px;
  font-weight: bold;
  height: 24px;
  line-height: 25px;
  position: relative;
  text-align: center;
  width: 24px;

  .popover {
    background-color: white;
    border-radius: 5px;
    bottom: 32px;
    box-shadow: 0 1px 4px 0 rgb(161 161 161 / 50%);
    color: #4a4a4a;
    display: none;
    font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 13px;
    font-weight: 600;
    left: -95px;
    padding: 7px 10px;
    position: absolute;
    width: 200px;
    z-index: 4;
    top: auto;

    &:before {
      border-top: 7px solid rgba(0,0,0,0);
      border-right: 7px solid transparent;
      border-left: 7px solid transparent;
      bottom: -7px;
      content: '';
      display: block;
      left: 50%;
      margin-left: -7px;
      position: absolute;
    }
  }

  &:hover {
    cursor: pointer;
    .popover {
      display: block;
      -webkit-animation: fade-in .3s linear 1, move-up .3s linear 1;
      -moz-animation: fade-in .3s linear 1, move-up .3s linear 1;
      -ms-animation: fade-in .3s linear 1, move-up .3s linear 1;
    }
  }
}

hr.info-tip--divider {
  margin-top: 5px;
  margin-bottom: 5px;
}

span.info-tip--title {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
}

@-webkit-keyframes fade-in {
  from   { opacity: 0; }
  to { opacity: 1; }
}
@-moz-keyframes fade-in {
  from   { opacity: 0; }
  to { opacity: 1; }
}
@-ms-keyframes fade-in {
  from   { opacity: 0; }
  to { opacity: 1; }
}
@-webkit-keyframes move-up {
  from   { bottom: 30px; }
  to { bottom: 42px; }
}
@-moz-keyframes move-up {
  from   { bottom: 30px; }
  to { bottom: 42px; }
}
@-ms-keyframes move-up {
  from   { bottom: 30px; }
  to { bottom: 42px; }
}

.bounce {
  position: absolute;
  -webkit-animation: bounce 1s infinite;
  right: 1px;
}

@-webkit-keyframes bounce {
  0%       { bottom:1px; }
  25%, 75% { bottom:2px; }
  50%      { bottom:3px; }
  100%     {bottom:0;}
}

div#pdl-small-modal-1 {
  max-width: inherit;
  margin: 0 auto;
  .modal-dialog {
    margin: 0 auto;
    width: 350px;
  }
}

.form-row ng-select {
  margin-top: 8px;
}

.ng-select.ng-select-single .ng-select-container .ng-value-container, .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value {
  width: 100%;
}

.ng-select.ng-select-disabled>.ng-select-container {
  background-color: #e9ecef;
  opacity: 1;
}

/* multi select styling */
body > app-root > div.app-container > app-tickets > app-datatable.multi > div > div > ngx-datatable > div > datatable-body > datatable-selection > datatable-scroller > datatable-row-wrapper > datatable-body-row > div.datatable-row-center.datatable-row-group.ng-star-inserted > datatable-body-cell:nth-child(1) {
  background-color: #fefbe0;
  border-bottom: 1px solid #e1ddbe;
  border-right: 1px solid #e1ddbe;
}

body > app-root > div.app-container > app-tickets > app-datatable.multi > div > div > ngx-datatable > div > datatable-body > datatable-selection > datatable-scroller > datatable-row-wrapper > datatable-body-row > div.datatable-row-center.datatable-row-group.ng-star-inserted > datatable-body-cell:nth-child(1) .abc-checkbox {
  padding-top: 12px;
  padding-left: 25px;
}

body > app-root > div.app-container > app-tickets > app-datatable.multi > div > div > ngx-datatable > div > datatable-header > div > div.datatable-row-center.ng-star-inserted > datatable-header-cell:nth-child(1) {
  padding-top: 5px;
}

body > app-root > div.app-container > app-tickets > app-datatable.multi > div > div > ngx-datatable > div > datatable-header > div > div.datatable-row-center.ng-star-inserted > datatable-header-cell:nth-child(1) .abc-checkbox {
  padding-left: 25px;
}

body > app-root > div.app-container > app-tickets > app-datatable.multi > div > div > ngx-datatable > div > datatable-header > div > div.datatable-row-center.ng-star-inserted > datatable-header-cell:nth-child(1) .sort-btn:before {
  background-image: none;
}

datatable-body-row.selected {
  background-color: #fdfcf3;
}

div > div > div.modal-body > div > form.hide-dates > pdl-dynamic-form > div > div:nth-child(2){
  display: none;
}

div > div > div.modal-body > div > form.hide-dates > pdl-dynamic-form > div > div:nth-child(3){
  display: none;
}

span.order-line {
  font-size: 13px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

span.company-name {
  margin-bottom: 20px;
}

span.company {

}

.company {
  font-size: 14px;
}


body {
  overflow-x: hidden;
}

.datatable-row-group:hover {
  background: #FDFCF3;
  cursor: pointer;
  box-shadow: inset 0 10px 0px -10px black, inset 0 -10px 1px -10px black;
}

#collapseLAI1 {
  padding: 0px !important;
  margin: 0px !important;
  .row {
    margin-right: 0px;
    margin-left: 0px;
    .col-sm-12 {
      padding-top: 10px;
      padding-bottom: 10px;
      padding-left: 0px;
    }
  }
}

span.filters-applied {
  margin-bottom: 10px;
  margin-top: 8px;
  display: block;
}

.list-accordion .list-accordion__item {
  padding-top: 0px;
  padding-bottom: 0px;
}

@media only screen and (max-width: 825px) {
  .input-group-text {
    background-color: #f6f6f600;
  }
}

@media only screen and (max-width: 550px) {
  .button--filters {
    content: "Edit";
  }
}

@media only screen and (max-width: 500px) {
  .header-bar__multi {
    display: none;
  }
  .header-bar__refresh.first {
    padding-right: 0px;
    margin-right: 0px;
    border-right: 0px solid gray !important;
  }
}

@media only screen and (max-width: 825px) {
  .filters-applied {
    font-size: 0px;
  }
}

// New Breakpoints Pulled from navbars.scss, unminified and pasted below. Updated from 625 to 825.

.nav-bar {
  display: flex;
  width: 100%;
  background-color: #fff;
  border-bottom: 1px solid #dcdcdc;
  height: 56px;
  padding-top: 16px;
  margin: 0 auto;
  box-shadow: 0px 0px 0px 4px #fff;
}

.nav-bar .nav-bar__logo {
  margin-right: auto;
  position: relative;
  top: -5px
}

.nav-bar .nav-bar__logo .nav-bar__logo__link .icon {
  color: #00483a;
  font-size: 26px
}

.nav-bar .nav-bar__logo .nav-bar__logo__link .icon svg {
  vertical-align: inherit
}

.nav-bar .nav-bar__logo .nav-bar__logo__link .icon span {
  color: #00483a;
  font-weight: 700;
  font-size: 18px;
  position: relative;
  top: -7px;
  left: 6px
}

.nav-bar .nav-bar__logo .nav-bar__logo__link .icon span.badge {
  color: white;
  font-weight: 700;
  font-size: 14px;
  position: relative;
  left: 6px
}

.nav-bar .nav-bar__logo .nav-bar__logo__link:active,
.nav-bar .nav-bar__logo .nav-bar__logo__link:hover,
.nav-bar .nav-bar__logo .nav-bar__logo__link:focus {
  text-decoration: none
}

.nav-bar .nav-bar__logo .nav-bar__logo__link:active .icon,
.nav-bar .nav-bar__logo .nav-bar__logo__link:hover .icon,
.nav-bar .nav-bar__logo .nav-bar__logo__link:focus .icon {
  color: #298b57
}

.nav-bar .nav-bar__logo .nav-bar__logo__link:active .icon span,
.nav-bar .nav-bar__logo .nav-bar__logo__link:hover .icon span,
.nav-bar .nav-bar__logo .nav-bar__logo__link:focus .icon span {
  color: #298b57
}

.nav-bar .nav-bar__logo .nav-bar__logo__link:active .icon g,
.nav-bar .nav-bar__logo .nav-bar__logo__link:hover .icon g,
.nav-bar .nav-bar__logo .nav-bar__logo__link:focus .icon g {
  fill: #298b57
}

@media only screen and (max-width: 500px) {
  // .nav-bar .nav-bar__logo {
  //   max-width: 100px !important;
  // }
  // .nav-bar__logo__link span {
  //   font-size: 0px !important;
  // }
}

@media only screen and (max-width: 525px) {
  .nav-bar .nav-bar__logo {
    display: none
  }
}

.nav-bar .nav-bar__mobile {
  margin-right: auto;
  padding-left: 16px;
  position: relative;
  top: 1px;
  display: none
}

.nav-bar .nav-bar__mobile .nav-bar__mobile__hamburger i {
  color: #00483a;
  font-size: 26px
}

.nav-bar .nav-bar__mobile .nav-bar__mobile__hamburger span {
  color: #00483a;
  font-weight: 700;
  font-size: 15px;
  position: relative;
  top: -7px;
  left: 10px
}

.nav-bar .nav-bar__mobile .nav-bar__mobile__hamburger:active,
.nav-bar .nav-bar__mobile .nav-bar__mobile__hamburger:hover,
.nav-bar .nav-bar__mobile .nav-bar__mobile__hamburger:focus {
  text-decoration: none;
  cursor: pointer
}

.nav-bar .nav-bar__mobile .nav-bar__mobile__hamburger:active i,
.nav-bar .nav-bar__mobile .nav-bar__mobile__hamburger:hover i,
.nav-bar .nav-bar__mobile .nav-bar__mobile__hamburger:focus i {
  color: #298b57
}

.nav-bar .nav-bar__mobile .nav-bar__mobile__hamburger:active span,
.nav-bar .nav-bar__mobile .nav-bar__mobile__hamburger:hover span,
.nav-bar .nav-bar__mobile .nav-bar__mobile__hamburger:focus span {
  color: #298b57
}

.nav-bar .nav-bar__menu {
  list-style-type: none;
  padding: 0
}

.nav-bar .nav-bar__menu .nav-bar__menu__item {
  display: inline-block
}

.nav-bar .nav-bar__menu .nav-bar__menu__item .nav-bar__menu__item__link {
  color: #4a4a4a;
  padding: 0px 14px 0px 14px
}

.nav-bar .nav-bar__menu .nav-bar__menu__item .nav-bar__menu__item__link:active,
.nav-bar .nav-bar__menu .nav-bar__menu__item .nav-bar__menu__item__link:hover,
.nav-bar .nav-bar__menu .nav-bar__menu__item .nav-bar__menu__item__link:focus {
  text-decoration: none;
  color: #298b57
}

@media only screen and (max-width: 825px) {
  .nav-bar .nav-bar__menu .nav-bar__menu__item .nav-bar__menu__item__link {
    padding: 0px 6px 0px 6px
  }
}

.nav-bar .nav-bar__menu .nav-bar__menu__item .nav-bar__menu__item__link--active {
  color: #00483a;
  font-weight: 700;
  border-bottom: 4px solid #298b57;
  padding-bottom: 14px
}

.nav-bar .nav-bar__menu .nav-bar__menu__item .nav-bar__menu__item__link--active:active,
.nav-bar .nav-bar__menu .nav-bar__menu__item .nav-bar__menu__item__link--active:hover,
.nav-bar .nav-bar__menu .nav-bar__menu__item .nav-bar__menu__item__link--active:focus {
  text-decoration: none
}

@media only screen and (max-width: 825px) {
  .nav-bar .nav-bar__menu {
    margin-right: auto
  }
}

.nav-bar .nav-bar__icons .nav-bar__icons__link {
  color: #00483a;
  padding: 4px
}

.nav-bar .nav-bar__icons .nav-bar__icons__link:active,
.nav-bar .nav-bar__icons .nav-bar__icons__link:hover,
.nav-bar .nav-bar__icons .nav-bar__icons__link:focus {
  text-decoration: none;
  color: #298b57;
  cursor: pointer
}

.nav-bar .nav-bar__icons .nav-bar__icons__link--first {
  padding-left: 16px
}

@media only screen and (max-width: 825px) {
  .nav-bar .nav-bar__icons {
    padding-right: 4px
  }
}

.nav-bar .nav-bar__search {
  display: block;
  width: 100%;
  float: left
}

.nav-bar--button-group {
  padding-top: 8px
}

.nav-bar--button-group .select-down {
  max-width: 168px;
  margin: 4px
}

.nav-bar--button-group .select-down .select__default {
  max-width: 168px;
  max-height: 32px;
  line-height: 16px;
  float: left
}

.nav-bar--button-group .form-control {
  max-width: 168px;
  margin: 4px;
  max-height: 32px;
  font-size: 13px
}

.nav-bar--button-group .description {
  font-size: 11px;
  font-weight: 400;
  line-height: 42px;
  margin-right: 10px
}

.nav-bar--button-group .nav-bar__logo {
  margin-right: auto;
  padding-left: 0px;
  position: relative;
  top: 3px
}

.nav-bar--button-group .nav-bar__logo .nav-bar__logo__link .icon span {
  left: 0px
}

.nav-bar--dark {
  background-color: #00483a
}

.nav-bar--dark .nav-bar__logo .nav-bar__logo__link .icon {
  color: #fff
}

.nav-bar--dark .nav-bar__logo .nav-bar__logo__link .icon span {
  color: #fff
}

.nav-bar--dark .nav-bar__logo .nav-bar__logo__link .icon g {
  fill: #fff
}

.nav-bar--dark .nav-bar__logo .nav-bar__logo__link:active .icon,
.nav-bar--dark .nav-bar__logo .nav-bar__logo__link:hover .icon,
.nav-bar--dark .nav-bar__logo .nav-bar__logo__link:focus .icon {
  color: #fff
}

.nav-bar--dark .nav-bar__logo .nav-bar__logo__link:active .icon span,
.nav-bar--dark .nav-bar__logo .nav-bar__logo__link:active .icon g,
.nav-bar--dark .nav-bar__logo .nav-bar__logo__link:hover .icon span,
.nav-bar--dark .nav-bar__logo .nav-bar__logo__link:hover .icon g,
.nav-bar--dark .nav-bar__logo .nav-bar__logo__link:focus .icon span,
.nav-bar--dark .nav-bar__logo .nav-bar__logo__link:focus .icon g {
  color: #fff;
  fill: #fff
}

.nav-bar--dark .nav-bar__menu .nav-bar__menu__item .nav-bar__menu__item__link {
  color: #fff;
  opacity: .8
}

.nav-bar--dark .nav-bar__menu .nav-bar__menu__item .nav-bar__menu__item__link:active,
.nav-bar--dark .nav-bar__menu .nav-bar__menu__item .nav-bar__menu__item__link:hover,
.nav-bar--dark .nav-bar__menu .nav-bar__menu__item .nav-bar__menu__item__link:focus {
  color: #fff;
  opacity: 1
}

.nav-bar--dark .nav-bar__menu .nav-bar__menu__item .nav-bar__menu__item__link--active {
  color: #fff;
  opacity: 1;
  border-bottom: 4px solid #298b57
}

.nav-bar--dark .nav-bar__menu .nav-bar__menu__item .nav-bar__menu__item__link--active:active,
.nav-bar--dark .nav-bar__menu .nav-bar__menu__item .nav-bar__menu__item__link--active:hover,
.nav-bar--dark .nav-bar__menu .nav-bar__menu__item .nav-bar__menu__item__link--active:focus {
  text-decoration: none
}

.nav-bar--dark .nav-bar__icons .nav-bar__icons__link {
  color: #298b57
}

.nav-bar--dark .nav-bar__icons .nav-bar__icons__link:active,
.nav-bar--dark .nav-bar__icons .nav-bar__icons__link:hover,
.nav-bar--dark .nav-bar__icons .nav-bar__icons__link:focus {
  color: #fff
}

.nav-bar--dark .nav-bar__mobile .nav-bar__mobile__hamburger i {
  color: #fff
}

.nav-bar--dark .nav-bar__mobile .nav-bar__mobile__hamburger span {
  color: #fff
}

.nav-bar--dark .nav-bar__mobile .nav-bar__mobile__hamburger:active i,
.nav-bar--dark .nav-bar__mobile .nav-bar__mobile__hamburger:hover i,
.nav-bar--dark .nav-bar__mobile .nav-bar__mobile__hamburger:focus i {
  color: #fff
}

.nav-bar--dark .nav-bar__mobile .nav-bar__mobile__hamburger:active span,
.nav-bar--dark .nav-bar__mobile .nav-bar__mobile__hamburger:hover span,
.nav-bar--dark .nav-bar__mobile .nav-bar__mobile__hamburger:focus span {
  color: #fff
}

@media only screen and (max-width: 825px) {
  .nav-bar--mobile .nav-bar__logo {
    display: none
  }
  .nav-bar--mobile .nav-bar__mobile {
    display: block;
    padding-left: 4px
  }
  .nav-bar--mobile .nav-bar__menu {
    display: none
  }
}

@media only screen and (max-width: 825px) {
  .nav-bar--abbreviated .nav-bar__logo {
    display: block;
    padding-left: 4px
  }
}

// Alex's Updates Below

.create-dropdown::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
  top: 1px;
  position: relative;
}

.notifier__notification--material {
  width: fit-content;
}

.app-view-email {
  app-root > div.app-container > app-email > div > table > tbody > tr:nth-child(1) > td > table > tbody > tr:nth-child(2) > td > table > tbody > tr:nth-child(1) > td > table > tbody > tr > td:nth-child(1) {
    color: #09483B;
    font-weight: bold;
    font-size: 18px;
  }

  app-root > div.app-container > app-email > div > table > tbody > tr:nth-child(1) > td > table > tbody > tr:nth-child(2) > td > table > tbody > tr:nth-child(2) > td > p:nth-child(1) {
    margin-top: 20px;
  }

  app-root > div.app-container > app-email > div > table > tbody > tr:nth-child(1) > td > table > tbody > tr:nth-child(2) > td > table > tbody > tr:nth-child(2) > td > p:nth-child(2) > a {
    color: #09483B;
    font-weight: bold;
  }

  app-root > div.app-container > app-email > div > table > tbody > tr:nth-child(1) > td > table > tbody > tr:nth-child(2) > td > table > tbody > tr:nth-child(3) > td > table > tbody > tr > th:nth-child(1) > table > tbody > tr:nth-child(1) > td {color: #09483B;font-weight: bold;font-size: 18px;margin-top: 20px;margin-bottom: 20px;display: block;border-bottom: 1px solid #e8e8e8;padding-bottom: 10px;}

  app-root > div.app-container > app-email > div > table > tbody > tr:nth-child(1) > td > table > tbody > tr:nth-child(2) > td > table > tbody > tr:nth-child(3) > td > table > tbody > tr > th:nth-child(1) > table > tbody > tr:nth-child(2) > td > table > tbody > tr > td:nth-child(1) {
    margin-bottom: 10px;
    font-weight: 500;
    display: block;
  }

  app-root > div.app-container > app-email > div > table > tbody > tr:nth-child(1) > td > table > tbody > tr:nth-child(2) > td > table > tbody > tr:nth-child(3) > td > table > tbody > tr > th:nth-child(3) > table {
    position: relative;
    top: -94px;
  }

  app-root > div.app-container > app-email > div > table > tbody > tr:nth-child(1) > td > table > tbody > tr:nth-child(2) > td > table > tbody > tr:nth-child(3) > td > table > tbody > tr > th:nth-child(3) > table > tbody > tr:nth-child(1) > td {
    color: #09483B;
    font-weight: bold;
    font-size: 18px;
    margin-top: 20px;
    margin-bottom: 20px;
    display: block;
    border-bottom: 1px solid #e8e8e8;
    padding-bottom: 10px;
  }

  app-root > div.app-container > app-email > div > table > tbody > tr:nth-child(1) > td > table > tbody > tr:nth-child(2) > td > table > tbody > tr:nth-child(3) > td > table > tbody > tr > th:nth-child(1) > table > tbody > tr:nth-child(2) > td > table > tbody > tr > td:nth-child(3) {
    font-weight: bold;
    color: black;
    font-size: 16px;
  }

  app-root > div.app-container > app-email > div > table > tbody > tr:nth-child(1) > td > table > tbody > tr:nth-child(2) > td > table > tbody > tr:nth-child(3) > td > table > tbody > tr > th:nth-child(3) > table > tbody > tr:nth-child(2) > td {
    font-weight: bold;
    color: black;
    font-size: 16px;
  }

  app-root > div.app-container > app-email > div > table > tbody > tr:nth-child(1) > td > table > tbody > tr:nth-child(2) > td > table > tbody > tr:nth-child(3) > td > table > tbody > tr > th:nth-child(3) > table > tbody > tr:nth-child(2) > td > a:nth-child(4) {
    color: #09483B;
    font-weight: bold;
    position: relative;
    top: -3px;
  }

  app-root > div.app-container > app-email > div > table > tbody > tr:nth-child(1) > td > table > tbody > tr:nth-child(2) > td > table > tbody > tr:nth-child(3) > td > table > tbody > tr > th:nth-child(3) > table > tbody > tr:nth-child(2) > td > a:nth-child(6) {
    color: #09483B;
    font-weight: bold;
    position: relative;
    top: -5px;
  }

  app-root > div.app-container > app-email > div.test > table > tbody > tr:nth-child(1) > td > table > tbody > tr:nth-child(2) > td > table > tbody > tr:nth-child(3) > td > table > tbody > tr > th:nth-child(3) > table > tbody > tr:nth-child(2) > td > a:nth-child(8) {
    color: #09483B;
    font-weight: bold;
    position: relative;
    top: -5px;
  }

  app-root > div.app-container > app-email > div.test > table > tbody > tr:nth-child(1) > td > table > tbody > tr:nth-child(2) > td > table > tbody > tr:nth-child(3) > td > table > tbody > tr > th:nth-child(3) > table > tbody > tr:nth-child(2) > td > a:nth-child(10) {
    color: #09483B;
    font-weight: bold;
    position: relative;
    top: -5px;
  }

  app-root > div.app-container > app-email > div.test > table > tbody > tr:nth-child(1) > td > table > tbody > tr:nth-child(2) > td > table > tbody > tr:nth-child(5) > td > table > tbody > tr > td:nth-child(3) > table {
    margin-bottom: 20px;
  }

  body > app-root > div.app-container > app-email > div.test > table > tbody > tr:nth-child(1) > td > table > tbody > tr:nth-child(2) > td > table > tbody > tr:nth-child(6) > td > table > tbody > tr > td:nth-child(3) > table {
    margin-bottom: 20px;
  }

  app-root > div.app-container > app-email > div > table > tbody > tr:nth-child(1) > td > table > tbody > tr:nth-child(2) > td > table > tbody > tr:nth-child(3) > td > table > tbody > tr > th:nth-child(3) > table > tbody > tr:nth-child(2) > td > b {

  }

  app-root > div.app-container > app-email > div > table > tbody > tr:nth-child(1) > td > table > tbody > tr:nth-child(2) > td > table > tbody > tr:nth-child(3) > td > table > tbody > tr > th:nth-child(3) > table > tbody > tr:nth-child(2) > td > br {
    content: "";
    display: block;
    margin-bottom: 10px; /* Adjust this value for more or less spacing */
  }

  app-root > div.app-container > app-email > div > table > tbody > tr:nth-child(1) > td > table {
    background: white;
    display: block;
    padding: 40px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  }

  .close-container {
    margin: 0 auto;
    width: 692px;
  }

  .close-email {float: right;position: relative;top: 57px;right: 19px;}

  app-root > div.app-container > app-email > div.test > table > tbody > tr:nth-child(1) > td > table > tbody > tr:nth-child(2) > td > table > tbody > tr:nth-child(1) > td > table > tbody > tr > td.pb-28.plr-0 > img {
    position: relative;
    top: -10px;
  }

  app-root > div.app-container > app-email > div.test > table > tbody > tr:nth-child(1) > td > table > tbody > tr:nth-child(2) > td > table > tbody > tr:nth-child(4) > td {
    color: #09483B;
    font-weight: bold;
    font-size: 18px;
    margin-top: 20px;
    margin-bottom: 20px;
    display: block;
    border-bottom: 1px solid #e8e8e8;
    padding-bottom: 10px;
  }

  // app-root > div.app-container > app-email > div.test > table > tbody > tr:nth-child(1) > td > table > tbody > tr:nth-child(2) > td > table > tbody > tr:nth-child(5) > td > table > tbody > tr > td:nth-child(3) > table > tbody > tr:nth-child(1) > td {
  //     font-weight: bold;
  // color: black;
  // font-size: 16px;
  // }

  app-root > div.app-container > app-email > div.test > table > tbody > tr:nth-child(1) > td > table > tbody > tr:nth-child(2) > td > table > tbody > tr:nth-child(5) > td > table > tbody > tr > td:nth-child(3) > table > tbody > tr:nth-child(2) > td > table > tbody > tr > th:nth-child(1) {
    width: 250px;
  }

  app-root > div.app-container > app-email > div.test > table > tbody > tr:nth-child(1) > td > table > tbody > tr:nth-child(2) > td > table > tbody > tr:nth-child(6) > td > table > tbody > tr > td:nth-child(3) > table > tbody > tr:nth-child(2) > td > table > tbody > tr > th:nth-child(1) {
    width: 250px;
  }

  app-root > div.app-container > app-email > div.test > table > tbody > tr:nth-child(1) > td > table > tbody > tr:nth-child(2) > td > table > tbody > tr:nth-child(7) > td > table > tbody > tr > td:nth-child(3) > table > tbody > tr:nth-child(2) > td > table > tbody > tr > th:nth-child(1) {
    width: 250px;
  }

  app-root > div.app-container > app-email > div.test > table > tbody > tr:nth-child(1) > td > table > tbody > tr:nth-child(2) > td > table > tbody > tr:nth-child(8) > td > table > tbody > tr > td:nth-child(3) > table > tbody > tr:nth-child(2) > td > table > tbody > tr > th:nth-child(1) {
    width: 250px;
  }

  app-root > div.app-container > app-email > div.test > table > tbody > tr:nth-child(1) > td > table > tbody > tr:nth-child(2) > td > table > tbody > tr:nth-child(5) > td > table > tbody > tr > td:nth-child(3) > table > tbody > tr:nth-child(2) > td > table > tbody > tr > th:nth-child(3) {
    width: 240px;
  }

  app-root > div.app-container > app-email > div.test > table > tbody > tr:nth-child(1) > td > table > tbody > tr:nth-child(2) > td > table > tbody > tr:nth-child(8) > td > table > tbody > tr > td:nth-child(3) > table > tbody > tr:nth-child(2) > td > table > tbody > tr > th:nth-child(3) {
    width: 240px;
  }

  app-root > div.app-container > app-email > div.test > table > tbody > tr:nth-child(1) > td > table > tbody > tr:nth-child(2) > td > table > tbody > tr:nth-child(7) > td > table > tbody > tr > td:nth-child(3) > table > tbody > tr:nth-child(2) > td > table > tbody > tr > th:nth-child(3) {
    width: 240px;
  }

  app-root > div.app-container > app-email > div.test > table > tbody > tr:nth-child(1) > td > table > tbody > tr:nth-child(2) > td > table > tbody > tr:nth-child(6) > td > table > tbody > tr > td:nth-child(3) > table > tbody > tr:nth-child(2) > td > table > tbody > tr > th:nth-child(3) {
    width: 240px;
  }

  app-root > div.app-container > app-email > div.test > table > tbody > tr:nth-child(1) > td > table > tbody > tr:nth-child(2) > td > table > tbody > tr:nth-child(5) > td > table > tbody > tr > td:nth-child(3) > table > tbody > tr:nth-child(2) > td > table > tbody > tr > th:nth-child(1) > table > tbody > tr > td.w-a > table > tbody > tr:nth-child(1) > td {
    font-weight: 500;
  }

  app-root > div.app-container > app-email > div.test > table > tbody > tr:nth-child(1) > td > table > tbody > tr:nth-child(2) > td > table > tbody > tr:nth-child(5) > td > table > tbody > tr > td:nth-child(3) > table > tbody > tr:nth-child(2) > td > table > tbody > tr > th:nth-child(1) > table > tbody > tr > td.w-25p > table > tbody > tr:nth-child(1) > td {
    font-weight: 500;
  }

  app-root > div.app-container > app-email > div.test > table > tbody > tr:nth-child(1) > td > table > tbody > tr:nth-child(2) > td > table > tbody > tr:nth-child(5) > td > table > tbody > tr > td:nth-child(3) > table > tbody > tr:nth-child(2) > td > table > tbody > tr > th:nth-child(3) > table > tbody > tr > td.w-21p.w-26p-438 > table > tbody > tr:nth-child(1) > td {
    font-weight: 500;
  }

  app-root > div.app-container > app-email > div.test > table > tbody > tr:nth-child(1) > td > table > tbody > tr:nth-child(2) > td > table > tbody > tr:nth-child(5) > td > table > tbody > tr > td:nth-child(3) > table > tbody > tr:nth-child(2) > td > table > tbody > tr > th:nth-child(3) > table > tbody > tr > td:nth-child(3) > table > tbody > tr:nth-child(1) > td {
    font-weight: 500;
  }

  app-root > div.app-container > app-email > div.test > table > tbody > tr:nth-child(1) > td > table > tbody > tr:nth-child(2) > td > table > tbody > tr:nth-child(5) > td > table > tbody > tr > td:nth-child(3) > table > tbody > tr:nth-child(2) > td > table > tbody > tr > th:nth-child(3) > table > tbody > tr > td:nth-child(5) > table > tbody > tr:nth-child(1) > td {
    font-weight: 500;
  }

  app-root > div.app-container > app-email > div.test > table > tbody > tr:nth-child(1) > td > table > tbody > tr:nth-child(2) > td > table > tbody > tr:nth-child(5) > td > table > tbody > tr > td:nth-child(3) > table > tbody > tr:nth-child(2) > td > table > tbody > tr > th:nth-child(3) > table > tbody > tr > td:nth-child(7) > table > tbody > tr:nth-child(1) > td {
    font-weight: 500;
  }

  app-root > div.app-container > app-email > div.test > table > tbody > tr:nth-child(1) > td > table > tbody > tr:nth-child(2) > td > table > tbody > tr:nth-child(6) > td > table > tbody > tr > td:nth-child(3) > table > tbody > tr:nth-child(2) > td > table > tbody > tr > th:nth-child(1) > table > tbody > tr > td.w-a > table > tbody > tr:nth-child(1) > td {
    font-weight: 500;
  }

  app-root > div.app-container > app-email > div.test > table > tbody > tr:nth-child(1) > td > table > tbody > tr:nth-child(2) > td > table > tbody > tr:nth-child(6) > td > table > tbody > tr > td:nth-child(3) > table > tbody > tr:nth-child(2) > td > table > tbody > tr > th:nth-child(1) > table > tbody > tr > td.w-25p > table > tbody > tr:nth-child(1) > td {
    font-weight: 500;
  }

  app-root > div.app-container > app-email > div.test > table > tbody > tr:nth-child(1) > td > table > tbody > tr:nth-child(2) > td > table > tbody > tr:nth-child(6) > td > table > tbody > tr > td:nth-child(3) > table > tbody > tr:nth-child(2) > td > table > tbody > tr > th:nth-child(3) > table > tbody > tr > td.w-21p.w-26p-438 > table > tbody > tr:nth-child(1) > td {
    font-weight: 500;
  }

  app-root > div.app-container > app-email > div.test > table > tbody > tr:nth-child(1) > td > table > tbody > tr:nth-child(2) > td > table > tbody > tr:nth-child(6) > td > table > tbody > tr > td:nth-child(3) > table > tbody > tr:nth-child(2) > td > table > tbody > tr > th:nth-child(3) > table > tbody > tr > td:nth-child(3) > table > tbody > tr:nth-child(1) > td {
    font-weight: 500;
  }

  app-root > div.app-container > app-email > div.test > table > tbody > tr:nth-child(1) > td > table > tbody > tr:nth-child(2) > td > table > tbody > tr:nth-child(6) > td > table > tbody > tr > td:nth-child(3) > table > tbody > tr:nth-child(2) > td > table > tbody > tr > th:nth-child(3) > table > tbody > tr > td:nth-child(5) > table > tbody > tr:nth-child(1) > td {
    font-weight: 500;
  }

  app-root > div.app-container > app-email > div.test > table > tbody > tr:nth-child(1) > td > table > tbody > tr:nth-child(2) > td > table > tbody > tr:nth-child(6) > td > table > tbody > tr > td:nth-child(3) > table > tbody > tr:nth-child(2) > td > table > tbody > tr > th:nth-child(3) > table > tbody > tr > td:nth-child(7) > table > tbody > tr:nth-child(1) > td {
    font-weight: 500;
  }

  app-root > div.app-container > app-email > div.test > table > tbody > tr:nth-child(1) > td > table > tbody > tr:nth-child(2) > td > table > tbody > tr:nth-child(7) > td > table > tbody > tr > td:nth-child(3) > table > tbody > tr:nth-child(2) > td > table > tbody > tr > th:nth-child(1) > table > tbody > tr > td.w-a > table > tbody > tr:nth-child(1) > td {
    font-weight: 500;
  }

  app-root > div.app-container > app-email > div.test > table > tbody > tr:nth-child(1) > td > table > tbody > tr:nth-child(2) > td > table > tbody > tr:nth-child(7) > td > table > tbody > tr > td:nth-child(3) > table > tbody > tr:nth-child(2) > td > table > tbody > tr > th:nth-child(1) > table > tbody > tr > td.w-25p > table > tbody > tr:nth-child(1) > td {
    font-weight: 500;
  }

  app-root > div.app-container > app-email > div.test > table > tbody > tr:nth-child(1) > td > table > tbody > tr:nth-child(2) > td > table > tbody > tr:nth-child(7) > td > table > tbody > tr > td:nth-child(3) > table > tbody > tr:nth-child(2) > td > table > tbody > tr > th:nth-child(3) > table > tbody > tr > td.w-21p.w-26p-438 > table > tbody > tr:nth-child(1) > td {
    font-weight: 500;
  }

  app-root > div.app-container > app-email > div.test > table > tbody > tr:nth-child(1) > td > table > tbody > tr:nth-child(2) > td > table > tbody > tr:nth-child(7) > td > table > tbody > tr > td:nth-child(3) > table > tbody > tr:nth-child(2) > td > table > tbody > tr > th:nth-child(3) > table > tbody > tr > td:nth-child(3) > table > tbody > tr:nth-child(1) > td {
    font-weight: 500;
  }

  app-root > div.app-container > app-email > div.test > table > tbody > tr:nth-child(1) > td > table > tbody > tr:nth-child(2) > td > table > tbody > tr:nth-child(7) > td > table > tbody > tr > td:nth-child(3) > table > tbody > tr:nth-child(2) > td > table > tbody > tr > th:nth-child(3) > table > tbody > tr > td:nth-child(5) > table > tbody > tr:nth-child(1) > td {
    font-weight: 500;
  }

  app-root > div.app-container > app-email > div.test > table > tbody > tr:nth-child(1) > td > table > tbody > tr:nth-child(2) > td > table > tbody > tr:nth-child(7) > td > table > tbody > tr > td:nth-child(3) > table > tbody > tr:nth-child(2) > td > table > tbody > tr > th:nth-child(3) > table > tbody > tr > td:nth-child(7) > table > tbody > tr:nth-child(1) > td {
    font-weight: 500;
  }

  app-root > div.app-container > app-email > div.test > table > tbody > tr:nth-child(1) > td > table > tbody > tr:nth-child(2) > td > table > tbody > tr:nth-child(8) > td > table > tbody > tr > td:nth-child(3) > table > tbody > tr:nth-child(2) > td > table > tbody > tr > th:nth-child(1) > table > tbody > tr > td.w-a > table > tbody > tr:nth-child(1) > td {
    font-weight: 500;
  }

  app-root > div.app-container > app-email > div.test > table > tbody > tr:nth-child(1) > td > table > tbody > tr:nth-child(2) > td > table > tbody > tr:nth-child(8) > td > table > tbody > tr > td:nth-child(3) > table > tbody > tr:nth-child(2) > td > table > tbody > tr > th:nth-child(1) > table > tbody > tr > td.w-25p > table > tbody > tr:nth-child(1) > td {
    font-weight: 500;
  }

  app-root > div.app-container > app-email > div.test > table > tbody > tr:nth-child(1) > td > table > tbody > tr:nth-child(2) > td > table > tbody > tr:nth-child(8) > td > table > tbody > tr > td:nth-child(3) > table > tbody > tr:nth-child(2) > td > table > tbody > tr > th:nth-child(3) > table > tbody > tr > td.w-21p.w-26p-438 > table > tbody > tr:nth-child(1) > td {
    font-weight: 500;
  }

  app-root > div.app-container > app-email > div.test > table > tbody > tr:nth-child(1) > td > table > tbody > tr:nth-child(2) > td > table > tbody > tr:nth-child(8) > td > table > tbody > tr > td:nth-child(3) > table > tbody > tr:nth-child(2) > td > table > tbody > tr > th:nth-child(3) > table > tbody > tr > td:nth-child(3) > table > tbody > tr:nth-child(1) > td {
    font-weight: 500;
  }

  app-root > div.app-container > app-email > div.test > table > tbody > tr:nth-child(1) > td > table > tbody > tr:nth-child(2) > td > table > tbody > tr:nth-child(8) > td > table > tbody > tr > td:nth-child(3) > table > tbody > tr:nth-child(2) > td > table > tbody > tr > th:nth-child(3) > table > tbody > tr > td:nth-child(5) > table > tbody > tr:nth-child(1) > td {
    font-weight: 500;
  }

  app-root > div.app-container > app-email > div.test > table > tbody > tr:nth-child(1) > td > table > tbody > tr:nth-child(2) > td > table > tbody > tr:nth-child(8) > td > table > tbody > tr > td:nth-child(3) > table > tbody > tr:nth-child(2) > td > table > tbody > tr > th:nth-child(3) > table > tbody > tr > td:nth-child(7) > table > tbody > tr:nth-child(1) > td {
    font-weight: 500;
  }

  app-root > div.app-container > app-email > div.test > table > tbody > tr:nth-child(1) > td > table > tbody > tr:nth-child(2) > td > table > tbody > tr:nth-child(6) > td > table > tbody > tr > td:nth-child(3) > table > tbody > tr:nth-child(2) > td > table {
    margin-bottom: 16px;
  }

  app-root > div.app-container > app-email > div.test > table > tbody > tr:nth-child(1) > td > table > tbody > tr:nth-child(2) > td > table > tbody > tr:nth-child(7) > td > table > tbody > tr > td:nth-child(3) > table > tbody > tr:nth-child(2) > td > table {
    margin-bottom: 16px;
  }

  app-root > div.app-container > app-email > div.test > table > tbody > tr:nth-child(1) > td > table > tbody > tr:nth-child(2) > td > table > tbody > tr:nth-child(5) > td > table > tbody > tr > td:nth-child(3) > table > tbody > tr:nth-child(1) > td {
    font-weight: bold;
    color: black;
    font-size: 16px;
  }

  app-root > div.app-container > app-email > div.test > table > tbody > tr:nth-child(1) > td > table > tbody > tr:nth-child(2) > td > table > tbody > tr:nth-child(6) > td > table > tbody > tr > td:nth-child(3) > table > tbody > tr:nth-child(1) > td {
    font-weight: bold;
    color: black;
    font-size: 16px;
  }

  app-root > div.app-container > app-email > div.test > table > tbody > tr:nth-child(1) > td > table > tbody > tr:nth-child(2) > td > table > tbody > tr:nth-child(7) > td > table > tbody > tr > td:nth-child(3) > table > tbody > tr:nth-child(1) > td {
    font-weight: bold;
    color: black;
    font-size: 16px;
  }

  app-root > div.app-container > app-email > div.test > table > tbody > tr:nth-child(1) > td > table > tbody > tr:nth-child(2) > td > table > tbody > tr:nth-child(8) > td > table > tbody > tr > td:nth-child(3) > table > tbody > tr:nth-child(1) > td {
    font-weight: bold;
    color: black;
    font-size: 16px;
  }

  app-root > div.app-container > app-new-order-page > div > nav > div > div > div.float-left.position-absolute > div > div > div > pdl-badge > span {
    padding: 5px 10px 8px 5px;
  }



  // app-root > div.app-container > app-email > div.test > table > tbody > tr:nth-child(1) > td > table > tbody > tr:nth-child(2) > td > table > tbody > tr:nth-child(5) > td > table > tbody > tr > td:nth-child(3) > table > tbody > tr:nth-child(2) > td > table > tbody > tr > th:nth-child(1) {
  // width: 250px
  // }

  // app-root > div.app-container > app-email > div.test > table > tbody > tr:nth-child(1) > td > table > tbody > tr:nth-child(2) > td > table > tbody > tr:nth-child(5) > td > table > tbody > tr > td:nth-child(3) > table > tbody > tr:nth-child(2) > td > table > tbody > tr > th:nth-child(1) > table > tbody > tr > td.w-a {
  // width: 75px;
  // }

  // app-root > div.app-container > app-email > div.test > table > tbody > tr:nth-child(1) > td > table > tbody > tr:nth-child(2) > td > table > tbody > tr:nth-child(5) > td > table > tbody > tr > td:nth-child(3) > table > tbody > tr:nth-child(2) > td > table > tbody > tr > th:nth-child(1) > table > tbody > tr > td.w-25p {
  // width: 75px;
  // }

  // app-root > div.app-container > app-email > div.test > table > tbody > tr:nth-child(1) > td > table > tbody > tr:nth-child(2) > td > table > tbody > tr:nth-child(5) > td > table > tbody > tr > td:nth-child(3) > table > tbody > tr:nth-child(2) > td > table > tbody > tr > th:nth-child(3) > table > tbody > tr > td.w-21p.w-26p-438 {
  // width: 150px;
  // }
}

.app-view-summary-report {
  .page-header {
    display: none !important;
  }
}

// .app-view-summary-report {
//   .page-header {
//     display: none !important;
//   }
//   .report-summary {
//     color: black;
//     position: relative;
//     top: -6px;
//   }
//   .order-table, .subtotals-table {
//     thead tr th {
//       background-color: #C3C3C3;
//       font-size: 13px;
//     }
//   }
//   .materials-table thead tr th {
//     background-color: #DFDEDE !important;
//   }
//   app-root > div.app-container > app-plant-schedule-summary-page > div > table:nth-child(7) > tbody > tr:nth-child(3) > td > table > thead > tr > th:nth-child(1) {
//     border-top-left-radius: 3px;
//   }
//   table.plant-header {
//     margin-bottom: 10px;
//   }
//   table.order-table, .subtotals-table {
//     border: 0px;
//     box-shadow: 0 0 4px 0 #9B9B9B;
//   }
//   table.order-table th {
//     border-bottom: 0px;
//   }
//   table.materials-table {
//     border: 0px;
//     font-size: 13px;
//     border: 0px;
//     box-shadow: 0 0 4px 0 #9B9B9B;
//   }
//   table.materials-table td {
//     border-top: solid 0px #252525;
//   }
//   table.subtotals-table td {
//     font-size: 13px;
//   }
//   app-root > div.app-container > app-plant-schedule-summary-page > div > h2:nth-child(24) {
//     margin-top: 0px;
//     margin-top: 0px;
//     font-weight: bold;
//     text-transform: uppercase;
//     color: #09474a;
//   }
//   app-root > div.app-container > app-plant-schedule-summary-page > div > h2:nth-child(21) {
//     font-weight: bold;
//     text-transform: uppercase;
//     color: #09474a;
//   }
//   app-root > div.app-container > app-plant-schedule-summary-page > div > h2:nth-child(23) {
//     font-weight: bold;
//     text-transform: uppercase;
//     color: #09474a;
//     margin-top: 0px;
//   }
//   app-root > div.app-container > app-plant-schedule-summary-page > div > h2:nth-child(22) {
//     font-weight: bold;
//     text-transform: uppercase;
//     color: #09474a;
//   }
//   .subtotals-table tr:nth-child(odd) {
//     background-color: #ffffff; /* Light grey color for odd rows */
//   }
//   .subtotals-table tr:nth-child(even) {
//     background-color: #F5F5F5; /* White color for even rows */
//   }
//   .materials-table tr:nth-child(odd) {
//     background-color: #ffffff; /* Light grey color for odd rows */
//   }
//   .materials-table tr:nth-child(even) {
//     background-color: #F5F5F5; /* White color for even rows */
//   }
//   .data-left {
//     text-align: left !important;
//     padding-left: 10px !important;
//     white-space: nowrap;
//     overflow: hidden;
//     text-overflow: ellipsis;
//     font-size: 13px;
//   }
// }

@media only screen and (max-width: 675px) {
  .calendar-widget {
    display: none !important;
  }
  .header-bar .drop-down {
    float: right;
    position: absolute;
    top: -48px;
    right: 70px;
  }
}

/* Order Form Page Specific Styling */
app-new-order-page {
  /* Then, apply the 50% width rule for viewports 60px and wider */
  @media (min-width: 600px) {
    .col-md-6 {
      flex: 0 0 50%;
      max-width: 50%;
    }
  }
}

/* Schedules Page Specific Styling */
.schedules-page {
  .app-container {
    max-width: revert;
    margin: revert;
  }
  .column-container {
    width: revert !important;
    padding-left: 10px;
    padding-right: 10px;
  }
  @media only screen and (min-width: 1200px) {
    .app-container {

      padding-left: 20px;
      padding-right: 20px;

    }
    .header-bar--collapse .header-bar__container {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
  @media only screen and (max-width: 1200px) {
    .column-container {
      width: 100%;
      padding-left: 0px;
      padding-right: 0px;
      left: -4px;
      position: relative;
    }
    .column-shadow {
      box-shadow: 0 0 4px #9b9b9b;
      border-bottom: 2px solid #d9d9d9;
    }
    .app-container {
      padding-left: 10px;
      padding-right: 10px;
    }
    .scroll-container {
      width: calc(100vw - 14px) !important;
    }
  }
}

/* Lindy Personnel: Hide/Show 1 of 2 Tables Based on Screen Size */
/* Custom CSS to show/hide elements based on screen width */
@media (min-width: 1000px) {
  .show-above-525 {
    display: block;
  }
  .show-below-525 {
    display: none;
  }
}
@media (max-width: 999px) {
  .show-above-525 {
    display: none;
  }
  .show-below-525 {
    display: block; /* or 'table' */
  }
}

.ltof .nav-bar {
  max-width: 1380px;
  margin-bottom: 10px;
}

.ltof .app-container {
  max-width: 1380px;
  padding-left: 20px;
  padding-right: 20px;
}

.ltof .form-row > [class*=col-] {
  padding-right: 5px;
  padding-left: 5px;
}

.ltof .form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.ltof .circle {
  width: 25px;
  line-height: 25px;
  border-radius: 50%;
  text-align: center;
  background-color: #077a07;
  color: white;
  display: inline-block;
  margin-right: 10px;
  font-size: 16px;
}

.ltof legend {
  border: none;
  color: #404040;
  font-size: 18px;
  font-weight: bold;
  padding-bottom: 5px;
  position: static;
  width: 100%;
}

.ltof label {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
  display: block;
}

.ltof .note {
  font-size: 14px;
  color: black;
  position: relative;
  top: -1px;
  float: right;
  font-weight: 500;
}

.ltof .note .approved {
  color:#5BB900ed;
  font-weight:bold;
}

.ltof .note .draft {
  color:#FFBA52;
  font-weight:bold;
}

.ltof .note .cancelled {
  color:#9EA274;
  font-weight:bold;
}

.ltof .email-sent {
  margin-left: 10px;
}

span.input-group-text.owl-dt-trigger-disabled {
  background-color: #eaecef !important;
  opacity: 1;
}


app-new-order-page label {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
  display: block;
}

app-new-order-page app-textbox > div > form > small {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (max-width: 900px) {
  .ltof .note {
    float: left;
    left: 5px;
    width: 100%;
    margin-top: 10px;
  }
  .ltof .email-sent {
    display: block;
    float: left;
    width: 100%;
    margin-left: 0px;
  }
}

@media (min-width: 992px) {
  .ltof .col-lg-1-5 {
    -ms-flex: 0 0 12.5%;
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
  .ltof .col-lg-4-5 {
    -ms-flex: 0 0 37.333333%;
    flex: 0 0 37.333333%;
    max-width: 37.333333%;
  }
}
